<template>
	<div class="table">
		<el-table
			border
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			style="width: 100%"
			height="60vh"
		>
			<el-table-column prop="nickname" label="用户名称" width="120">
			</el-table-column>
			<el-table-column prop="id" label="ID" width="120"> </el-table-column>
			<el-table-column prop="mobile" label="联系方式"> </el-table-column>
			<el-table-column label="认证状态">
				<template slot-scope="scope">
					{{ scope.row.type == 1 ? "未验证" : "已验证" }}
				</template>
			</el-table-column>
			<el-table-column label="认证类型" v-if="">
				<template slot-scope="scope">
					<div v-if="scope.row.userinfo">
						{{
							scope.row.userinfo.auth_type == 1
								? "个人"
								: scope.row.userinfo.auth_type == 2
								? "企业"
								: "项目"
						}}
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="name" label="用户权限">
            </el-table-column> -->
			<el-table-column label="用户状态">
				<template slot-scope="scope">
					{{ scope.row.state == 1 ? "正常" : "禁用" }}
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span class="pointer" @click="toDetail(scope.row)">查看</span>

					<span class="pointer margin_left_10" @click="toEdit(scope.row)"
						>编辑</span
					>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)"
						>删除</span
					>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { destroyusers } from "@/api/user";
export default {
	props: {
		tableData: Object,
	},
	// watch: {
	//     "tableData":function(val,nval){
	//         this.newtableData=val
	//         this.rendertable=!this.rendertable
	//     }
	// },
	name: "",
	data() {
		return {
			value: true,
			// newtableData:[],
			// rendertable:true
		};
	},
	methods: {
		toDetail(row) {
			if (row.userinfo.auth_type == 1) {
				this.$router.push({
					path: "/addpersonal",
					query: { id: row.id, type: "detail" },
				});
			} else if (row.userinfo.auth_type == 2) {
				this.$router.push({
					path: "/addfirm",
					query: { id: row.id, type: "detail" },
				});
			} else {
				this.$router.push({
					path: "/addnormal",
					query: { id: row.id, type: "detail" },
				});
			}
		},
		toEdit(row) {
			console.log(row.auth_type);
			if (row.userinfo.auth_type == 1) {
				this.$router.push({
					path: "/addpersonal",
					query: { id: row.id, type: "edit" },
				});
			} else if (row.userinfo.auth_type == 2) {
				this.$router.push({
					path: "/addfirm",
					query: { id: row.id, type: "edit" },
				});
			} else {
				this.$router.push({
					path: "/addnormal",
					query: { id: row.id, type: "edit" },
				});
			}
		},
		toDelete(row) {
			this.$confirm("此操作将删除该条用户, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					destroyusers({ types: 1, status: 0, id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
